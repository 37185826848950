var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"form",attrs:{"autocomplete":"new-password","lazy-validation":_vm.lazy},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"id":"password","label":"Senha","name":"password","prepend-icon":"mdi-lock-open","type":"password","rules":[
        _vm.ruleRequired('Defina uma senha'),
        _vm.ruleMinLen(6),
        _vm.ruleMaxLen(30)
      ]},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('v-text-field',{attrs:{"id":"password_confirmation","label":"Confirmação de senha","name":"password_confirmation","prepend-icon":"mdi-lock","type":"password","rules":[
        _vm.ruleRequired('Confirme a senha'),
        _vm.ruleMinLen(6),
        _vm.ruleMaxLen(30),
        _vm.ruleMatch(_vm.formData.password)
      ]},model:{value:(_vm.formData.password_confirmation),callback:function ($$v) {_vm.$set(_vm.formData, "password_confirmation", $$v)},expression:"formData.password_confirmation"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }