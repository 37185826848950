<template>
  <div>
    <v-form autocomplete="new-password" ref="form" v-model="valid" :lazy-validation="lazy">
      <v-text-field
        v-model="formData.password"
        id="password"
        label="Senha"
        name="password"
        prepend-icon="mdi-lock-open"
        type="password"
        :rules="[
          ruleRequired('Defina uma senha'),
          ruleMinLen(6),
          ruleMaxLen(30)
        ]"
      />
      <v-text-field
        v-model="formData.password_confirmation"
        id="password_confirmation"
        label="Confirmação de senha"
        name="password_confirmation"
        prepend-icon="mdi-lock"
        type="password"
        :rules="[
          ruleRequired('Confirme a senha'),
          ruleMinLen(6),
          ruleMaxLen(30),
          ruleMatch(formData.password)
        ]"
      />
    </v-form>
  </div>
</template>
<script>
import validations from "@/mixins/validations";
export default {
  mixins: [validations],
  props: {
    formData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      valid: true,
      lazy: false
    };
  },
  methods: {
    validateForm() {
      return this.$refs.form.validate();
    }
  }
};
</script>
